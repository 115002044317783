// import html2canvas from "html2canvas";
// import Base64 from "base-64";
import Vue from 'vue'
import { jsPDF } from "jspdf";
import JQ from 'jquery';
// import '@/utils/weiruanhheiti-normal';
import {
	Loading
} from 'element-ui';

export function exportPdfByShippingMark(DomId,listData,name,option) {
	
	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});
	
	let topMargin = 20;
	let leftMargin = 20;
	
	let unit = 2.835;
	let formatSize = [210*unit,297*unit];
	if('2'==option.size){
		formatSize = [210*unit,210*unit];
	}
	// 一英寸=72pt(点)=96px(像素) 1PT等于0.3527MM 1mm==2.835px
	let pdf = new jsPDF({
		// orientation: 'p',
		unit: 'pt',
		// unit: 'mm',
		// format: 'a4',
		format: formatSize,
		// putOnlyUsedFonts:true,
		// floatPrecision: 16 // or "smart", default is 16
	});
	// pdf.setFont('weiruanhheiti');
	// let pdf = new jsPDF();
	for(let i=0,ln=listData.length;i<ln;i++){
		let item  = listData[i];
		
		//当前Dom
		let dom_bar_list = JQ(DomId).find(".mark-item");
		let barCodeItem = dom_bar_list.eq(i);
		
		//logo
		if(option.showLogo){
			let logoImg = '/static/image/logo_en.png';
			pdf.addImage(logoImg, 'png', leftMargin, topMargin, 80,16.64);
		}
		
		//箱数索引
		pdf.setFontSize(16);
		pdf.text(leftMargin+360, topMargin+30, 'Box '+item.CtnNo+' of '+item.totalCTN);
		
		//横线
		pdf.moveTo(leftMargin,  topMargin+30+10);
		pdf.lineTo(leftMargin+500, topMargin+30+10);
		pdf.stroke();
		
		//客户
		if(option.showCus){
			pdf.setFontSize(16);
			pdf.text(leftMargin, topMargin+30+40, 'Client:');
			// pdf.setFontSize(30);
			// pdf.text(leftMargin, topMargin+80, item.cusName.length>10?(item.cusName.substring(0,10)+'..'):item.cusName);
			let CusName = barCodeItem.find('.mark-name').find('img');
			// pdf.setFontSize(20);
			// pdf.text(leftMargin, topMargin+60, goodsName);
			pdf.addImage(CusName.attr('src'), 'png', leftMargin, topMargin+30+40+5, CusName.width(),
				CusName.height());
		}
		
		//sku
		if(option.showSku){
			pdf.setFontSize(16);
			pdf.text(leftMargin, topMargin+150, 'SKU:');
			pdf.setFontSize(30);
			pdf.text(leftMargin, topMargin+150+35, item.goodsSku);
		}
		
		//每箱件数
		pdf.setFontSize(16);
		pdf.text(leftMargin, topMargin+220, 'PCS:');
		pdf.setFontSize(30);
		pdf.text(leftMargin, topMargin+220+35, item.ctnPCS);
		
		
		// pdf.setFontSize(18);
		// pdf.text(leftMargin+360, topMargin+80+30+30+30, 'PCS/CTN: '+ item.ctnPCS);
		
		// pdf.setFontSize(18);
		// pdf.text(leftMargin+360, topMargin+80+30+30, 'Total CTN: '+item.totalCTN);
		// pdf.setFontSize(18);
		// pdf.text(leftMargin+360, topMargin+80+30+30+30, 'Total PCS: '+item.totalPCS);
		
		//柜号
		pdf.setFontSize(24);
		pdf.text(leftMargin, topMargin+330, item.packingNo);
			
		// barCode he listData数量应该一样，一个箱唛一个code
		// let barCodeTagList = document.getElementsByClassName("barcode");
		
		
		
		
		// let barCode = barCodeTagList[i];
		//编码，条形码
		if(option.showBarCode){
			let barCode = barCodeItem.find('.mark-code').find('img');
			//判断一维码时候匹配。防止出现意外情况
			let code = barCode.attr('code');
			let img_src = barCode.attr('src');
			let width = barCode.width();
			let height = barCode.height();
			console.log("code",code);
			if(barCode && code==item.code){
				pdf.addImage(img_src, 'png', leftMargin, topMargin+300+36, width, height);
			}
		}
		
		//附加信息
		if(option.addText && option.addText.length>0){
			pdf.setFontSize(14);
			pdf.text(leftMargin+360, topMargin+300+200, option.addText[0]);
		}
		
		if(i<ln-1){
			pdf.addPage();
		}
		
	}
	let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
	pdf.save(name+"_"+dateStr+".pdf");
	loadingInstance.close();
	
}

// export {
// 	exportPdfByShippingMark
// };
