<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_e6a250894a04db4c')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_e6a250894a04db4c')" append-to-body :visible.sync="dialog"
		:direction="'rtl'" size="1000px">

		<div style="padding: 10px;">
			<el-descriptions :title="item.goodsSku" :column="2" border v-for="(item,index) in tableData" :key="index">
				<el-descriptions-item :label="$t('i18nn_6cdece641436d7ab')">
					<el-tag type="" v-if="'0' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
					<el-tag type="success" v-else-if="'10' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
					<el-tag type="warning" v-else-if="'20' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
					<el-tag type="danger" v-else-if="'30'==item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
					<el-tag type="info" v-else>{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('hytxs0000060')">
					{{item.cusName}}
				</el-descriptions-item>
				<el-descriptions-item :label="'SKU'">
					{{item.goodsSku}}
				</el-descriptions-item>
				
				<el-descriptions-item :label="$t('Storage.skuInfo.SKU_title')">
					<div>
						<div>{{item.goodsName}}</div>
						<div v-if="item.goodsName!=item.goodsNameEn">{{item.goodsNameEn}}</div>
					</div>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_a85af4ba66084d28')+'('+$t('Storage.skuInfo.length_width_height')+')'">
					<div>
						<span>{{item.goodsLength}}</span>*
						<span>{{item.goodsWidth}}</span>*
						<span>{{item.goodsHeight}}</span>
					</div>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('Storage.skuInfo.weight')">
					{{item.goodsWeight}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('Storage.skuInfo.unit')">
					{{item.sizeUnitName}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_96f1a4b17e75892d')+'/'+$t('i18nn_d5d972e6063a3180')+'(in/lb)'">
					<div>
						<div>
							<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{item.goodsINCLength}}</span>
						</div>
						<div>
							<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{item.goodsINCWidth}}</span>
						</div>
						<div>
							<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{item.goodsINCHeight}}</span>
						</div>
						<div>
							<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{item.goodsLBWeight}}</span>
						</div>
					</div>
				</el-descriptions-item>
				<!-- <el-descriptions-item :label="$t('i18nn_6d8f9b0c398319ff')">
					<div v-if="item.isBattery">
						{{item.isBatteryName}}
					</div>
				</el-descriptions-item> -->
				<el-descriptions-item :label="$t('Storage.skuInfo.price')">
					{{item.declarePrice}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('Storage.skuInfo.safety_stock')">
					{{item.safeStock}}
				</el-descriptions-item>
				<el-descriptions-item :label="'UPC'">
					{{item.upcBrand}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('15b3627faddccb1d')">
					{{item.remark}}
				</el-descriptions-item>
				<el-descriptions-item :label="'SKU CODE'" :span="2">
					<barcode :code="item.goodsSku"></barcode>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('Storage.skuInfo.SKU_pictures')" :span="2">
					<el-image lazy style="width: auto; height: 50px" fit="contain" :src="item.goodsImg"
						:preview-src-list="tableData.map(itemPre=> { return itemPre.goodsImg})">
						<div slot="error" class="image-slot" style="font-size: 50px;"><i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
				</el-descriptions-item>
			</el-descriptions>
		</div>

		<!-- <el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" size="small"
			v-loading="loading_load">
			<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')">
			</el-table-column>

			<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
				<template slot-scope="scope">
					<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					<el-tag type="danger" v-else-if="'30'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
				</template>
			</el-table-column>

			<el-table-column prop="goodsSku" label="SKU"></el-table-column>

			<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
				<template slot-scope="scope">
					<div>
						<div>{{scope.row.goodsName}}</div>
						<div v-if="scope.row.goodsName!=scope.row.goodsNameEn">{{scope.row.goodsNameEn}}</div>
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="goodsLength"
				:label="$t('Storage.skuInfo.size')+'('+$t('Storage.skuInfo.length_width_height')+')'">
				<template slot-scope="scope">
					<div>
						<span>{{scope.row.goodsLength}}</span>*
						<span>{{scope.row.goodsWidth}}</span>*
						<span>{{scope.row.goodsHeight}}</span>
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

			<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>
			
			<el-table-column prop="sizeUnit" :label="'重量/长度计算(in/lb)'" min-width="150">
				<template slot-scope="scope2">
						<div>
							
							<div>
								<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{scope2.row.goodsINCLength}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{scope2.row.goodsINCWidth}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{scope2.row.goodsINCHeight}}</span>
							</div>
							<div>
								<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{scope2.row.goodsLBWeight}}</span>
							</div>
						</div>
				</template>
			</el-table-column>

			<el-table-column prop="isBatteryName" :label="$t('i18nn_6d8f9b0c398319ff')">
				<template slot-scope="scope">
					<div v-if="scope.row.isBattery">
						<el-tag type="info" v-if="'0' == scope.row.isBattery">{{ scope.row.isBatteryName }}</el-tag>
						<el-tag type="warning" v-else-if="'1' == scope.row.isBattery">{{ scope.row.isBatteryName }}
						</el-tag>
						<el-tag type="" v-else>{{ scope.row.isBatteryName }}</el-tag>
					</div>
				</template>
			</el-table-column>


			<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>

			<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
				<template slot-scope="scope">
					<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
						<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
					</el-popover>
				</template>
			</el-table-column>

			<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

			<el-table-column prop="goodsSku" :label="'SKU CODE'" width="400px">
				<template slot-scope="scope">
					<barcode :code="scope.row.goodsSku"></barcode>
				</template>
			</el-table-column>
			
			<el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
				<template slot-scope="scope">
					<div>
						<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg"
							:preview-src-list="[scope.row.goodsImg]">
							<div slot="error" class="image-slot" style="font-size: 40px;"><i
									class="el-icon-picture-outline"></i></div>
						</el-image>
					</div>
				</template>
			</el-table-column>
		</el-table> -->

		<!-- <div slot="footer" class="dialog-footer"> -->
		<!-- <el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
		<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import barcode from '@/components/Common/barcode.vue';

	export default {
		props: {
			openTime: {},
			goodsSku: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		components: {
			barcode
		},
		data() {
			return {
				dialog: false,
				loading_load: false,
				tableData: [],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				if (this.goodsSku) {
					this.tableData = [];
					this.getListData();
				} else {
					this.$message.warning("SKU为空");
				}
			},

			//请求分页数据
			getListData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhMyGoodsSkuPageList, {
						offset: "0",
						limit: "100",
						goodsSku: this.goodsSku ? this.goodsSku : null,
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							this.tableData = data.rows;
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>